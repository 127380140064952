
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { searchPatients } from "@/api/patient.api";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import PatientNotes from "@/modules/patient/PatientNotes.vue";
import useFilters from "@/modules/common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  emptyListError?: string;
  items: [];
  pagination: Pagination;
  orderBy: string;
  IsAdvancedOrderBy: boolean;
  isDecr: boolean;
  panel: any;
  dict: {
    encounterStatusCodes: [];
    patientPayers: [];
    facilities: [];
  };
  filter: {
    reviewDateFrom: Date | string | null;
    reviewDateTo: Date | string | null;
    globalSearch: string | null;
  };
  modalPatientId;
  modal;
}
export default defineComponent({
  name: "PatientsAccounts",
  components: { PaginationUi, PatientNotes },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {
      orderList,
      putFiltersToUrl,
      getFiltersFromUrl,
      cleanHeaderFilter,
      getFilter,
    } = useFilters();
    orderList.value = [
      {
        name: "Patient ID",
        key: "accountId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Name",
        key: "name",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "DOB",
        key: "dob",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Balance Due from Patient",
        key: "patientAccount.outstandingBalancePatient",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Balance Due from Insurance",
        key: "patientAccount.outstandingBalanceInsurance",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Unapplied Credit",
        key: "patientAccount.unappliedCredit",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Patient Balance Age",
        key: "patientAccount.patientBalanceAge",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Account Status",
        key: "accountStatus",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Review Date",
        key: "reviewDate",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        encounterStatusCodes: [],
        patientPayers: [],
        facilities: [],
      },
      filter: {
        reviewDateFrom: null,
        reviewDateTo: null,
        globalSearch: null,
      },
      modalPatientId: null,
      modal: false,
    });

    async function cleanFilter() {
      data.orderBy = "default";
      data.IsAdvancedOrderBy = true;
      data.isDecr = false;
      data.filter.reviewDateFrom = null;
      data.filter.reviewDateTo = null;
      data.filter.globalSearch = null;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      organizationId.value = getOrganization();
      setCurrentPageTitle("Patients Accounts");
      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      putFiltersToUrl();
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value
            .filter((item) => {
              return !item.IsAdvancedOrderBy;
            })
            .map((item) => {
              return item.key;
            }),
        },
        reviewDateFrom: data.filter.reviewDateFrom,
        reviewDateTo: data.filter.reviewDateTo,
        GlobalSearch: data.filter.globalSearch,
      };

      const res = await searchPatients(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = orderList.value.filter((f) => {
          return (
            f.keyword != undefined && f.keyword !== "" && f.keyword != null
          );
        });
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
    }

    function selectItem(item) {
      router.push({ path: "patientAccounts/patient/" + item.id + "/" });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function selectNotes(patient) {
      data.modalPatientId = patient.id;
      data.modal = true;
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      selectNotes,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
    };
  },
});
